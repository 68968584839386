import axios from "axios";
import cogoToast from "cogo-toast";
import { setAddress, addNewAddress, updateAddress, deleteAddressById } from "../slices/address-slice"

const API_URL = process.env.REACT_APP_API_HOST;

export const addAddress = (data) => {
    return async (dispatch) => {
        try {
            await axios.post(`${API_URL}/address`, data, {
                headers: {
                    "x-auth-token": localStorage.getItem("xat"),
                },
            }).then((res) => {
                dispatch(addNewAddress(res.data.createdAddress));
                cogoToast.success("Address Added Successfully!", {
                    position: "bottom-left",
                });
            }).catch((err) => {
                cogoToast.error("Address not added", { position: "bottom-left" });
            });
        } catch (error) {
            cogoToast.error("Something Went wrong", { position: "bottom-left" });
        }
    };
};

export const editAddress = (id, data) => {
    return async (dispatch) => {
        try {
            await axios.put(`${API_URL}/address/${id}`, data, {
                headers: {
                    "x-auth-token": localStorage.getItem("xat"),
                },
            }).then((res) => {
                dispatch(updateAddress({ id: res.data.updatedAddress.id, updatedAddress: res.data.updatedAddress }));
                cogoToast.success("Address Updated Successfully!", {
                    position: "bottom-left",
                });
            }).catch((err) => {
                cogoToast.error("Address not updated", { position: "bottom-left" });
            });
        } catch (error) {
            cogoToast.error("Something went wrong", { position: "bottom-left" });
        }
    };
};

export const deleteAddress = (id) => {
    return async (dispatch) => {
        try {
            await axios.delete(`${API_URL}/address/${id}`, {
                headers: {
                    "x-auth-token": localStorage.getItem("xat"),
                },
            }).then((res) => {
                dispatch(deleteAddressById(res.data.removedAddress.id));
                cogoToast.success("Address Deleted Successfully!", {
                    position: "bottom-left",
                });
            }).catch((err) => {
                cogoToast.error("Address Not deleted", { position: "bottom-left" });
            });
        } catch (error) {
            cogoToast.error("Something went wrong", { position: "bottom-left" });
        }
    };
};

export const getAddress = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.get(`${API_URL}/address`, {
                headers: {
                    "x-auth-token": localStorage.getItem("xat"),
                },
            })
            dispatch(setAddress(data));
        } catch (error) {
            cogoToast.error("Something went wrong", { position: "bottom-left" });
        }
    };
};
