import axios from 'axios';
import cogoToast from 'cogo-toast';
import { setAuth, UnsetAuth, registerAuth, redirectState, existingAuth } from '../slices/auth-slice';

const API_URL = process.env.REACT_APP_API_HOST


export const userLogin = (email, password) => {
    return async dispatch => {
        try {
            await axios.post(`${API_URL}/auth/login`, {
                email,
                password
            }).then(
                (res) => {
                    dispatch(setAuth(res.data))
                    console.log(res.data)
                    localStorage.setItem('xat', res.data.token);
                    cogoToast.success('Login Successfull', { position: "bottom-left" });
                }
            ).catch(
                (err) => {
                    cogoToast.error('Invalid Credentials', { position: "bottom-left" });
                }
            )
        } catch (error) {
            cogoToast.error('Login Failed', { position: "bottom-left" });
        }
    };
}

export const userLogout = () => {
    return async dispatch => {
        try {
            dispatch(UnsetAuth());
            dispatch(redirectState())
            localStorage.removeItem('xat');
            cogoToast.success('Logout Successfull', { position: "bottom-left" });
        } catch (error) {
            cogoToast.error('Logout Failed', { position: "bottom-left" });
        }
    }
}

export const registerUser = (data) => {
    return async dispatch => {
        try {
            await axios.post(`${API_URL}/auth/signup`, {
                data
            }).then(
                (res) => {
                    dispatch(registerAuth(res.data))
                    cogoToast.success("Registered Successfully! Welcome To America's Silver Collection ", { position: "bottom-left" });
                }
            ).catch(
                (err) => {
                    cogoToast.error('Registration Failed', { position: "bottom-left" });
                }
            )
        } catch (error) {
            cogoToast.error('Registration Failed', { position: "bottom-left" });
        }
    }
}

export const existingCustomer = (data) => {
    return async dispatch => {
        try {
            await axios.post(`${API_URL}/auth/register-existing`, data).then(
                (res) => {
                    dispatch(existingAuth(res.data))
                    cogoToast.success(<div> <b>Thank You</b> <div> We Recive your request please wait for your approval </div> </div>, { position: 'top-center' });
                }
            ).catch(
                (err) => {
                    cogoToast.error('User not registered', { position: "bottom-left" });
                }
            )
        } catch (error) {
            cogoToast.error('Something went wrong', { position: "bottom-left" });
        }
    };
}

export const editProfile = (data) => {
    return async (dispatch) => {
        try {
            await axios
                .post(`${API_URL}/auth/edit`, data, {
                    headers: {
                        "x-auth-token": localStorage.getItem("xat"),
                    },
                })
                .then((res) => {
                    dispatch(registerAuth(res.data));
                    cogoToast.success("Profile Updated Successfully!", {
                        position: "bottom-left",
                    });
                })
                .catch((err) => {
                    cogoToast.error("Invalid Credentials", { position: "bottom-left" });
                });
        } catch (error) {
            cogoToast.error("Login Failed", { position: "bottom-left" });
        }
    };
};

export const passwordReset = (data) => {
    return async (dispatch) => {
        try {
            await axios
                .post(`${API_URL}/auth/change-password`, data, {
                    headers: {
                        "x-auth-token": localStorage.getItem("xat"),
                    },
                })
                .then((res) => {
                    cogoToast.success("Password Reset Successfully!", {
                        position: "bottom-left",
                    });
                })
                .catch((err) => {
                    cogoToast.error("Invalid Credentials", { position: "bottom-left" });
                });
        } catch (error) {
            cogoToast.error("Login Failed", { position: "bottom-left" });
        }
    };
};
