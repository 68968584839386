import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import LayoutOne from "../../layouts/LayoutOne";
import SEO from "../../components/seo";
import { useDispatch, useSelector } from "react-redux";
import { existingCustomer } from "../../store/actions/authActions";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";

const ExistingCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirect } = useSelector((state) => state.auth);

  const { pathname } = window.location;
  const [verifyPass, setVerifyPass] = useState(false);
  const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [Register, setRegister] = useState({
    firstName: "",
    lastName: "",
    customerAccount: "",
    email: "",
    password: "",
    verifyPassword: "",
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  const checkUserEmailExistance = async (e) => {
    if (Register.email.trim() !== '' && isValidEmail(Register.email)) {
      const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/check-email`, {
        email: Register.email
      });

      if (data.emailExists === true) {
        setCheckEmail(true)
      } else {
        setCheckEmail(false)
      }
    }
  }

  useEffect(() => {

    checkUserEmailExistance()

    // eslint-disable-next-line
  }, [Register.email]);


  useEffect(() => {
    if (Register.password !== Register.verifyPassword) {
      setVerifyPass(true);
    } else {
      setVerifyPass(false);
    }
  }, [Register.password, Register.verifyPassword]);

  const handleChange = (e) => {
    setRegister({
      ...Register,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Register.firstName === '')
      return cogoToast.error(<div> <b>Error</b> <div> First Name is Required </div> </div>, { position: 'top-center' });

    if (Register.lastName === '')
      return cogoToast.error(<div> <b>Error</b> <div> Last Name is Required </div> </div>, { position: 'top-center' });

    if (Register.customerAccount === '')
      return cogoToast.error(<div> <b>Error</b> <div> Customer # is Required </div> </div>, { position: 'top-center' });

    if (Register.email === '')
      return cogoToast.error(<div> <b>Error</b> <div> Email is Required </div> </div>, { position: 'top-center' });

    if (!emailRegex.test(Register.email))
      return cogoToast.error(<div><b>Error</b><div>Invalid Email</div></div>, { position: 'top-center' });

    if (checkEmail)
      return cogoToast.error(<div> <b>Error</b> <div> Email already associated with another account </div> </div>, { position: 'top-center' });

    if (Register.password === '')
      return cogoToast.error(<div> <b>Error</b> <div> Password is Required </div> </div>, { position: 'top-center' });

    if (Register.password.length < 6)
      return cogoToast.error(<div> <b>Error</b> <div> Password must be grater than 6 characters </div> </div>, { position: 'top-center' });

    if (Register.verifyPassword === '')
      return cogoToast.error(<div> <b>Error</b> <div> Verify Password is Required </div> </div>, { position: 'top-center' });

    if (Register.password !== Register.verifyPassword)
      return cogoToast.error(<div> <b>Error</b> <div> Password not matched </div> </div>, { position: 'top-center' })

    if (Register.password.length < 6 && Register.verifyPassword.length < 6)
      return cogoToast.error(<div> <b>Error</b> <div> Password must be grater than 6 characters </div> </div>, { position: 'top-center' });

    dispatch(existingCustomer(Register));
    setSubmitBtnDisable(true);

    setTimeout(() => {
      setRegister({
        firstName: "",
        lastName: "",
        customerAccount: "",
        email: "",
        password: "",
        verifyPassword: "",
      });
      setSubmitBtnDisable(false);
    }, 1000);


  };

  useEffect(() => {
    if (redirect) {
      navigate("/");
    }

    // eslint-disable-next-line
  }, [redirect]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Existing Customer | Asc925"
        description="Existing customer page of America's Silver Collection."
      />
      <LayoutOne
        headerTop="visible"
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Existing Customer",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="login-register-area pt-50 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-md-10 ms-auto me-auto">
                <div className="login-register-wrapper">
                  <div className="login-form-container">
                    <div className="login-register-form">
                      <div className="row">
                        <h2 className="mb-4 text-center">Existing Customer</h2>
                        <hr />
                        <div className="col-md-6 mb-3 mt-2">
                          <label htmlFor="firstName" className="mb-1">
                            First Name <span className="reqiured">*</span>
                          </label>
                          <input
                            autocomplete="off"
                            onChange={handleChange}
                            type="text"
                            value={Register.firstName}
                            name="firstName"
                            placeholder="Fisrt Name"
                            required
                            id="firstName"
                          />
                        </div>
                        <div className="col-md-6 mb-3 mt-2">
                          <label htmlFor="lastName" className="mb-1">
                            Last Name <span className="reqiured">*</span>
                          </label>
                          <input
                            autocomplete="off"
                            onChange={handleChange}
                            type="text"
                            value={Register.lastName}
                            name="lastName"
                            placeholder="Last Name"
                            required
                            id="lastName"
                          />
                        </div>
                        <div className="col-md-6 mb-3 mt-2">
                          <label htmlFor="customerAccount" className="mb-1">
                            Customer #<span className="reqiured">*</span>
                          </label>
                          <input
                            autocomplete="off"
                            onChange={handleChange}
                            type="text"
                            value={Register.customerAccount}
                            name="customerAccount"
                            placeholder="Customer #"
                            required
                            id="customerAccount"
                          />
                        </div>
                        <div className="col-md-6 mb-3 mt-2">
                          <label htmlFor="email" className="mb-1">
                            Email <span className="reqiured">*</span>
                          </label>
                          <input
                            autocomplete="off"
                            onChange={handleChange}
                            type="email"
                            value={Register.email}
                            name="email"
                            placeholder="Email"
                            required
                            id="email"
                          />
                          {
                            checkEmail && (
                              <small className="mt-2 reqiured">
                                Email Already Exists
                              </small>
                            )
                          }
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="password" className="mb-1">
                            Password <span className="reqiured">*</span>
                          </label>
                          <input
                            autocomplete="off"
                            onChange={handleChange}
                            type="password"
                            name="password"
                            value={Register.password}
                            placeholder="Password"
                            required
                            id="password"
                          />
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="verifyPassword" className="mb-1">
                            Verify Password <span className="reqiured">*</span>
                          </label>
                          <input
                            autocomplete="off"
                            onChange={handleChange}
                            type="password"
                            name="verifyPassword"
                            value={Register.verifyPassword}
                            placeholder="Password"
                            required
                            id="verifyPassword"
                          />
                          {verifyPass ? (
                            <small className="mt-2 required">
                              Password Not Matched
                            </small>
                          ) : null}
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            onClick={handleSubmit}
                            className="button-box mt-3"
                            disabled={submitBtnDisable}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ExistingCustomer;
