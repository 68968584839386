import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name: "order",
    initialState: {
        order: {},
        loading: false,
        error: null,
        myOrders: [],
        redirect: false,
    },
    reducers: {
        createOrderRequest: (state, action) => {
            state.loading = true;
        },

        createOrderSuccess: (state, action) => {
            state.loading = false;
            state.order = action.payload;
            state.redirect = true;
        },
        createOrderFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.redirect = false;
        },

        getMyOrdersRequest: (state, action) => {
            state.loading = true;
        },
        getMyOrdersSuccess: (state, action) => {
            state.loading = false;
            state.redirect = false;
            state.myOrders = action.payload;
        },
        getMyOrdersFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.redirect = false;
        },

        setRedirect: (state, action) => {
            state.redirect = false;
        },

        orderPaymentSuccess: (state, action) => {
            state.loading = false;
            state.myOrders = [...state.myOrders, action.payload];
        },
        resetOrder: (state, action) => {
            state.loading = false;
            state.myOrders = [];
        },
    },
});

export const {
    createOrderSuccess,
    orderPaymentSuccess,
    createOrderFail,
    createOrderRequest,
    setRedirect,
    resetOrder,
    getMyOrdersRequest,
    getMyOrdersSuccess,
    getMyOrdersFail,
} = orderSlice.actions;

// export const createOrder = (order) => async (dispatch, getState) => {
//     try {
//         dispatch(createOrderRequest());

//         dispatch(createOrderSuccess(order));
//     } catch (error) {
//         dispatch(createOrderFail(error));
//     }
// };

// export const createOrder = (order) => {
//     return async dispatch => {
//         try {
//             dispatch(createOrderRequest());

//             dispatch(createOrderSuccess(order));
//         } catch (error) {
//             dispatch(createOrderFail(error));
//         }
//     };
// }

// export const orderPayment = (order) => {
//     return async dispatch => {
//         try {
//             dispatch(orderPaymentRequest());
//             // eslint-disable-next-line
//             const token = localStorage.getItem('token');

//             const config = {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'x-auth-token': token,
//                 },
//             };

//             const { data } = await axios.post(`${host}/orders`, order, config);
//             dispatch(orderPaymentSuccess(data));
//         } catch (error) {
//             dispatch(orderPaymentFail(error));
//         }
//     }
// }

export default orderSlice.reducer;
