import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MobileNavMenu = () => {
  // const { t } = useTranslation();

  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);
  const renderSubMenu = (subcategories) => {
    return (
      <ul className="sub-menu">
        {subcategories.map((subcategory) => (
          <li key={subcategory.id}>
            <Link to={subcategory.link}>{subcategory.name}</Link>
          </li>
        ))}
      </ul>
    );
  };

  const renderMenu = (menuItems) => {
    return (
      <ul>
        {menuItems.map((menuItem) => (
          <li key={menuItem.id} className="menu-item-has-children">
            <Link to={menuItem.link}>{menuItem.category}</Link>
            {menuItem.subcategories && renderSubMenu(menuItem.subcategories)}
          </li>
        ))}
      </ul>
    );
  };

  const navMenu = categories.map((category) => {
    const matchingSubcategories = subcategories.filter(
      (subcategory) => subcategory.category._id === category._id
    );

    const formattedSubcategories = matchingSubcategories.map((subcategory) => {
      return {
        id: subcategory._id,
        name: subcategory.name,
        link: `/category/${category.slug}/${subcategory.slug}`,
      };
    });

    return {
      id: category._id,
      category: category.name,
      link: `/category/${category.slug}`,
      subcategories:
        formattedSubcategories.length > 0 ? formattedSubcategories : undefined,
    };
  });

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      {renderMenu(navMenu)}
    </nav>
  );
};

export default MobileNavMenu;
